import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/TeamMemberLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`After receiving a degree in Economics, Roy went on to obtain his Law Degree from the University of Alberta in 1979. Upon being admitted to the Law Society in 1980, Roy continued on the dual track of grain farming and practicing law. At the age of 36 he was designated Queens Counsel.`}</p>
    <p>{`Given the time management issues associated with both roles, often it involved finding innovative ways to streamline workflow on the farm, resulting in Roy being one of the early farmers to adopt zero-tillage. Dating back to 1911 the family farm is now 4th generation and operated by his son and his family, where they run a purebred Angus operation.`}</p>
    <p>{`Roy’s law practice has always been restricted to corporate, commercial and real estate law. A significant portion of his real estate practice relates to farm and ranch land, as well as land with aggregate deposits. He is known for taking a special interest in clients and working effortlessly to provide unique solutions to what might be seen as common recurring issues. One of those issues is related to witnessing landowners not having a good method to sell their land. Often owners would find the process very stressful in dealing with potential buyers, with the added complexity whether it be family, friends and/or neighbors. The CLHbid.com platform was created by Roy to offer a stress-free selling platform to owners, yet at the same time allowing all interested parties to bid in such a manner than ensures owners receive top market value for what they have worked all their lives for.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      